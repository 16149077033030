.Post {
  font-size: 1rem !important;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  margin-bottom: 60px;
  margin-left : 10%;
  margin-right: 10%;
  width: 100%;
}
.Post-user {
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
}
.Post-user button {
  position: absolute;
  right: 10px;
  top: 10px;
  height:45px;
}
.Post-user-avatar {
  width: 30px;
  height: 30px;
}
.Post-user-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.Post-user-nickname {
  margin-left: 12px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}
.Post-image-bg {
  background-color: #efefef;
}
.Post-image img {
  display: block;
  width: 100%;
}
.Post-caption {
  text-align: left;
  /* padding: 16px 16px; */
  padding: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
  white-space: pre-wrap;
}
.Post-created {
  text-align: left;
  padding: 0;
  padding-top: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.Post-caption strong {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}
.vjs-fade-out {
  display: none;
  visibility: hidden;
  opacity: 0;
}